import { ReactNode } from 'react';
import { IconStyled, WrapperStyled } from './styled';

type ErrorProps = {
  children: ReactNode;
  className?: string;
};

const Error = ({ children, className = '' }: ErrorProps) => {
  return (
    <WrapperStyled className={className}>
      <IconStyled name="info" width={20} height={20} />
      {children}
    </WrapperStyled>
  );
};

export default Error;
