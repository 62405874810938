import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const WrapperStyled = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography700};
  background-color: rgba(235, 87, 87, 0.15);
`;

export const IconStyled = styled(Icon)`
  margin-right: 4px;
  fill: ${({ theme }) => theme.colors.error};
  transform: scaleY(-1);
`;
