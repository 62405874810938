import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';

export const SupportBotWrapperStyled = styled.div<{ variant: 'static' | 'fixed' }>`
  position: ${({ variant }) => (variant === 'fixed' ? 'fixed' : 'static')};
  right: 15px;
  bottom: 16px;
  z-index: 10;

  @media ${(props) => props.theme.breakpoints.desktop} {
    right: 100px;
    bottom: 20px;
  }
`;

export const IconStyled = styled(Icon)`
  margin-right: 4px;
  fill: ${({ theme }) => theme.colors.typography100};
`;

export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  width: auto;
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.typography100};
  background-color: ${({ theme }) => theme.colors.lunarViolet};

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 10px 22px;
  }
`;
